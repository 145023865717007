import {useRef} from 'react';
import {v4} from 'uuid';
import {config} from '../../config';
import {isAnalyticsBlockListDomain} from './domain-helpers';

interface AnalyticsProps {
  /** URL to which instructions will be transmitted as analytics */
  endpoint: string;
  /** Token used to identify a specific guest anonymously */
  token?: string;
}

type AnalyticsStatus = 'anonymous' | 'default' | 'disabled';

/**
 * Read analytics token from storage, if one does not exist create one. If the
 * current domain should not send analytics provides `undefined`.
 */
export function useAnalytics(
  status: AnalyticsStatus = 'default'
): AnalyticsProps | undefined {
  const location = typeof window === 'undefined' ? undefined : window.location;
  const key = 'lcd/analytics-token';
  const result = useRef<AnalyticsProps>({
    endpoint: `${config.endpointBase}/attendee/analytics`,
    token: localStorage.getItem(key) ?? undefined,
  });
  if (isAnalyticsBlockListDomain(config, location) || status === 'disabled') {
    return undefined;
  }
  if (status === 'anonymous') {
    // If explicitly anonymous remove the `token`
    result.current.token = undefined;
  } else if (typeof result.current.token === 'undefined') {
    // If not explicitly anonymous and token is missing then create one
    const token =
      typeof window?.crypto?.randomUUID === 'function'
        ? crypto.randomUUID()
        : v4();
    localStorage.setItem(key, token);
    result.current.token = token;
  }
  return result.current;
}
